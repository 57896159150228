.banner {
    position: relative;
    color: white;
    text-align: center;
    background-color: #b32436;
    border-bottom: 2px solid #901f2e;
    margin-top: -15px;
    margin-bottom: 10px;
}

.banner h1 {
    font-size: 4.25em;        
}

.banner h3 {
    font-size: 1.50em;
    margin-top: 0px;
}

@media screen and (max-width: 992px) {
    .banner h1 {
        font-size: 3.25em;
    }
    .banner h3 {
        font-size: 1.50em;
    }
}

@media screen and (max-width: 768px) {
    .banner h1 {
        font-size: 2.25em;
        padding-top: 10px;
    }
    .banner h3 {
        font-size: 1.25em;
    }
}

@media screen and (max-width: 450px) {
    .banner h1 {
        font-size: 1.50em;
        padding-top: 10px;
    }
    .banner h3 {
        font-size: 1.00em;
    }
}
