p.error {
    color: #9f3a38;
  }
  
  span.error {
    color: #9f3a38;
  }
  
  .ui.search.dropdown .menu {
    max-height: 27rem;
  }
  
  @media screen and (min-width: 0px) and (max-width: 767px) {
    .mobile-and-tablet {
      display: block;
    }
  }
  
  @media screen and (min-width: 768px) {
    .mobile-and-tablet {
      display: none !important;
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 430px) {
    .mobile-only {
      display: block;
    }
  }
  
  @media screen and (min-width: 431px) {
    .mobile-only {
      display: none !important;
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 767px) {
    .desktop-only {
      display: none;
    }
  }
  
  @media screen and (min-width: 768px) {
    .desktop-only {
      display: block;
    }
  }
  
  /* bottom menu */
  .menu .item.clickable:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.03);
  }
  
  @media screen and (min-width: 0px) and (max-width: 430px) {
    .hide-mobile {
      display: none !important;
    }
  }
  
  @media screen and (min-width: 431px) {
    .hide-mobile {
      display: block;
    }
  }
  
  .loading {
    animation: rotation 2s infinite linear;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* course */
  @media print {
    #course-container {
      display: none;
    }
  }
  
  .clickable:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 5px;
  }

  .state-dropdown {
    font-size: x-large !important;
  }  

  .state-dropdown .menu .item {
    font-size: x-large !important;
  }